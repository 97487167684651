import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import React, { Suspense, useEffect, useState } from 'react'

import { Button } from '../components/common/Button'
import { Modal } from '../components/common/Modal'
import { ImageSection } from '../components/contents/ImageSection'
import Single from '../components/contents/Single'
import optionSrc1 from '../images/option-icon_1.svg'
import optionSrc2 from '../images/option-icon_2.svg'
import optionSrc3 from '../images/option-icon_3.svg'
import optionSrc4 from '../images/option-icon_4.svg'
import { mq, rem } from '../styles/mixin.styles'
import { colors, space } from '../styles/variables.styles'

// 動画を遅延読み込み
const Video = React.lazy(() => import('../components/contents/Video'))

type Props = {
  location: {
    [key: string]: string
  }
}

const FeaturePage = ({ location }: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (!openModal) {
      setIsPlaying(false)
    } else {
      setIsPlaying(true)
    }
  }, [openModal])

  return (
    <>
      <Single
        slug="feature"
        subRead="パレット調達支援システム『iPaS』の主な機能"
        subTitle="FEATURES"
        readText="iPaSはパレット調達業務の業務効率化を実現します。"
        description="これまでのパレット調達業務はさまざまな調整業務や手戻りが頻発し非効率な業務が行われていました。また業務の属人化によって、担当者ごとに品質が異なり、業務の効率化や標準化が進まない状況でした。iPaSはこれまでのパレット調達業務を見直し、入口から出口までを一気通貫で繋ぎ、システムによるサポートで属人化を防ぐことで、大幅な業務効率化を実現するツールです。"
        pathname={location.pathname}
      >
        <div css={featureStyles}>
          <Button
            style="play"
            onClick={() => {
              setOpenModal(!openModal)
            }}
            align="left"
          >
            紹介動画を見る
          </Button>
          <div className="feature__sections">
            <ImageSection
              title="バーチャル荷姿構想作成"
              description="<p>3次元データを利用してバーチャルで<br />簡単に荷姿構想が描けます。</p>"
              list={['構想設計<br />品質の向上', '構想設計<br />L/T短縮']}
            >
              <StaticImage
                src="../images/feature_1.jpg"
                width={400}
                height={208}
                alt="iPaSパレット調達支援システムのバーチャル荷姿構想作成画面のスクリーンショット。テンプレートから作成、3D構成図と書かれている"
                placeholder="none"
                loading="eager"
              />
            </ImageSection>

            <ImageSection
              title="バーチャル荷姿検討会"
              description="<p>3次元データを利用して事前にバーチャル荷姿検討会が実施できます。</p>"
              list={['荷姿検討会削減による<br />現場の負荷軽減']}
              layout="left"
            >
              <StaticImage
                src="../images/feature_2.jpg"
                width={400}
                height={208}
                alt="iPaSパレット調達支援システム画面のスクリーンショット。3次元データのダウンロードボタンが表示されている"
                placeholder="none"
              />
            </ImageSection>

            <ImageSection
              title="チャット機能"
              description="<p>チャット形式で常に関係者と情報共有しながら仕様確定が進められます。チャット上の添付資料は時系列に並んで保存されます。</p>"
              list={['仕様検討から確定<br />までのL/T短縮', '仕様検討<br />内容の充実']}
            >
              <StaticImage
                src="../images/feature_3.webp"
                width={400}
                height={208}
                alt="iPaSパレット調達支援システム内の、チャット画面のスクリーンショット。"
                placeholder="none"
              />
            </ImageSection>

            <ImageSection
              title="過去データの閲覧"
              description="<p>過去データ（仕様、価格、購入先など）をイベントと紐付け全て保存し、簡単に新規案件に参照登録できます。</p>"
              list={['過去事例をベースにした<br />調達価格の適正化']}
              layout="left"
            >
              <StaticImage
                src="../images/feature_4.webp"
                width={400}
                height={208}
                alt="iPaSパレット調達支援システム内、過去データの一覧画面のスクリーンショット。パレット検索と書かれている"
                placeholder="none"
              />
            </ImageSection>

            <ImageSection
              title="荷姿テンプレート"
              description="<p>システムに登録している豊富なテンプレートを組合せ簡単に荷姿構想を作成できます。</p>"
              list={['調達業務の<br />標準化', '調達業務<br />時間の削減', '調達業務<br />負荷の軽減']}
            >
              <StaticImage
                src="../images/feature_5.webp"
                width={400}
                height={208}
                alt="iPaSパレット調達支援システムのバーチャル荷姿構想作成画面のスクリーンショット。テンプレートから作成、3D構成図と書かれている"
                placeholder="none"
              />
            </ImageSection>
          </div>

          <section className="feature__option">
            <h2>オプション機能</h2>
            <ul>
              <li>
                <div className="feature__option__icon">
                  <img src={optionSrc1} width={85} height={61} alt="" />
                </div>
                <p>権限管理</p>
              </li>
              <li>
                <div className="feature__option__icon">
                  <img src={optionSrc2} width={71} height={71} alt="" />
                </div>
                <p>CSV出力</p>
              </li>
              <li>
                <div className="feature__option__icon">
                  <img src={optionSrc3} width={69} height={69} alt="" />
                </div>
                <p>電話サポート</p>
              </li>
              <li>
                <div className="feature__option__icon">
                  <img src={optionSrc4} width={63} height={73} alt="" />
                </div>
                <p>ファイル添付</p>
              </li>
            </ul>
          </section>
        </div>
      </Single>
      <Modal openModal={openModal} setOpenModal={setOpenModal} style="video">
        <Suspense fallback={<p>loading...</p>}>
          <Video isPlaying={isPlaying} setIsPlaying={setIsPlaying} />
        </Suspense>
      </Modal>
    </>
  )
}

export default FeaturePage

export const featureStyles = css`
  margin-top: ${space.md};
  .feature__sections {
    margin-top: clamp(${rem(44)}, 10vw, ${rem(108)});
    display: grid;
    row-gap: clamp(${rem(60)}, 16vw, ${rem(180)});
  }
  .feature__option {
    max-width: ${rem(688)};
    margin: clamp(${rem(60)}, 10vw, ${rem(180)}) auto 0;
    h2 {
      font-size: clamp(${rem(24)}, 5vw, ${rem(32)});
      text-align: center;
      padding-bottom: ${rem(12)};
      margin: 0 0 clamp(${rem(30)}, 10vw, ${rem(60)});
      position: relative;
      &::before {
        display: none;
      }
      &::after {
        content: '';
        width: ${rem(113)};
        height: ${rem(4)};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: ${colors.mainBlue.hex};
      }
    }
    ul {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2, minmax(142px, 1fr));
      gap: clamp(${rem(30)}, 5vw, ${rem(40)});
      li {
        display: flex;
        min-height: ${rem(170)};
        text-align: center;
        border: ${rem(1)} solid ${colors.grayBorder.hex};
        border-radius: ${rem(10)};
        display: block;
        padding: ${rem(25)} ${rem(15)};
        width: 100%;
        height: 100%;

        p {
          color: ${colors.mainBlue.hex};
          font-weight: bold;
          margin-top: ${rem(18)};
        }
      }
    }
    .feature__option__icon {
      display: flex;
      min-height: ${rem(72)};
      justify-content: center;
      align-items: center;
    }
  }

  ${mq('lg')} {
    margin-bottom: ${rem(80)};
    .feature__option {
      ul {
        grid-template-columns: repeat(4, minmax(142px, 1fr));
      }
    }
  }
`
