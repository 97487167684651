import { css } from '@emotion/react'
import React, { ReactNode, useMemo } from 'react'
import { mq, rem } from '../../styles/mixin.styles'
import { colors, space } from '../../styles/variables.styles'

type ImageSectionProps = {
  title: string
  description: string
  list: string[]
  layout?: 'right' | 'left'
  children?: ReactNode
}

/**
 * ImageSection
 *
 * @param ImageSectionProps image section props
 * @returns ImageSection contents
 */
export const ImageSection = ({ title, description, list, layout, children }: ImageSectionProps) => {
  const currentLayout = useMemo(() => {
    if (layout === 'left') {
      return 'image-section image-section--left-image'
    }
    return 'image-section image-section--right-image'
  }, [layout])

  return (
    <section className={currentLayout} css={stylesWrap}>
      <div className="image-section__text-block">
        <h2 className="image-section__title">{title}</h2>
        {description && (
          <div
            className="image-section__description"
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        )}

        <ul className="image-section__list">
          {list.map((item, index) => (
            <li key={index}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item
                }}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="image-section__image">{children}</div>
    </section>
  )
}

const stylesWrap = css`
  display: flex;
  flex-direction: column;
  margin: 0;
  row-gap: ${rem(20)};
  .image-section__text-block {
    grid-area: text-area;
    display: flex;
    flex-direction: column;
    row-gap: ${rem(18)};
    text-decoration: none;
    color: currentColor;
  }
  h2.image-section__title {
    font-size: clamp(${rem(28)}, 3.458vw, ${rem(32)});
    line-height: clamp(${rem(41)}, 10vw, ${rem(46)});
    color: ${colors.mainBlue.hex};
    margin: 0;
    &::before {
      content: '';
      display: none;
    }
  }
  .image-section__description {
    line-height: 1.8;
  }
  .image-section__list {
    display: flex;
    list-style: none;
    column-gap: clamp(${space.xs}, 3.458vw, ${space.md});
    > li {
      font-size: clamp(${rem(14)}, 3.458vw, ${rem(16)});
      text-align: center;
      line-height: 1.5;
      padding: ${rem(10)} ${rem(18)};
      background: ${colors.subBlue3.hex};
      border-radius: ${rem(8)};
    }
  }
  .image-section__image {
    grid-area: image-area;
    display: flex;
    width: fit-content;
    margin: auto;
    box-shadow: 4px 4px 4px 0px #0000001a;
    height: min-content;
  }
  ${mq('lg')} {
    display: grid;
    gap: ${rem(23)};
    grid-template-columns: ${rem(400)} 1fr;
    &.image-section--left-image {
      grid-template-areas: 'image-area text-area';
      .image-section__text-block {
        margin-left: ${rem(10)};
      }
    }
    &.image-section--right-image {
      grid-template-columns: 1fr ${rem(400)};
      grid-template-areas: 'text-area image-area';
    }
  }
`
